<template>
  <div>
    <c-tab
      :dense="true"
      type="pagetop"
      align="left"
      :inlineLabel="true"
      :tabItems="tabItems"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
        />
      </template>
    </c-tab>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'preventiveMaintenanceRecomTab',
  data() {
    return {
      tab: 'check',
      tabItems: [
        { name: 'check', icon: 'perm_media', label: '예방점검 추천목록', component: () => import(`${'./preventiveMaintenanceRecomCheck.vue'}`) },
        { name: 'daily', icon: 'perm_media', label: '일상점검 추천목록', component: () => import(`${'./preventiveMaintenanceRecomDaily.vue'}`) },
      ],
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
    },
  }
};
</script>
